import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { downloadFile, accessAPI } from "../../utils/utils.js";
import logoHorizontal from "../../images/logoHorizontal.png";
import "./descargarCertificado.css";

export default function DescargarCertificado() {
  const [estado, setEstado] = useState("CARGANDO");
  const [nombreArchivo, setNombreArchivo] = useState();

  // Carga el id del proceso de la URL
  const { certificadoHash } = useParams();

  // Busca el nombre del archivo y lo guarda en state
  const buscarNombreDelArchivo = useCallback(() => {
    accessAPI(
      "GET",
      `certificado/${certificadoHash}`,
      null,
      (respuesta) => {
        setNombreArchivo(respuesta.nombreArchivo);
      },
      (respuesta) => {
        setEstado("NO_RECONOCIDO");
      }
    );
  }, [certificadoHash]);

  // Cuando carga el componente, va a buscar el certificado
  useEffect(() => {
    // Primero busca el nombre del archivo
    buscarNombreDelArchivo();
  }, [buscarNombreDelArchivo]);

  // Cuando determina el nombre del archivo, lo descarga
  useEffect(() => {
    if (nombreArchivo) {
      downloadFile(
        `certificado/descargar/${certificadoHash}`,
        nombreArchivo,
        (response) => {
          setEstado("DESCARGADO");
        },
        (respuesta) => {
          setEstado("NO_RECONOCIDO");
        }
      );
    }
  }, [nombreArchivo, certificadoHash]);

  return (
    <div className="seccion registro">
      <header className="flexContainer izquierda">
        <img className="logo centrado" src={logoHorizontal} alt="logo" />
      </header>
      <main className="mensajeCliente">
        <h2>Descarga de certificados</h2>
        {estado === "CARGANDO" && (
          <div>
            <p>
              Estamos procesando tu solicutud, en unos segundos comenzará la
              descarga.
            </p>
            <p>
              Si luego de unos minutos la descarga no comienza automáticamente,
              presiona{" "}
              <span className="linkTexto" onClick={buscarNombreDelArchivo}>
                aquí
              </span>{" "}
              para reiniciarla.
            </p>
          </div>
        )}
        {estado === "NO_RECONOCIDO" && (
          <div>
            <h3>Ocurrió un error</h3>
            <p>
              No reconocimos esta dirección, esto puede ser porque el
              certificado no está vigente o porque hubo un error al copiar la
              misma. Por favor verifica que la dirección ingresada sea la
              correcta.
            </p>
            <p>
              Si continúas con problemas por favor ponte en contacto con
              nosotros que rápidamente te ayudaremos.
            </p>
          </div>
        )}
        {estado === "DESCARGADO" && (
          <div>
            <h3>¡Felicitaciones! Tu certificado se descargó correctamente.</h3>
            <p>
              Lo puedes encontrar como "<i>{nombreArchivo}</i>" en tu carpeta de
              descargas.
            </p>
            <p>
              Queremos agradecerte nuevamente por confiar en Qualis Fidelis y
              estamos siempre a las órdenes.
            </p>
          </div>
        )}
      </main>
      <div className="viasDeContacto">
        Puedes contactarte con nosotros a través de:
        <p>
          <a href="mailto:info@qualisfidelis.com">info@qualisfidelis.com</a>
        </p>
        <p>+598 92 33 13 03</p>
      </div>

      <footer>
        <div className="flexContainer izquierda inline botonera">
          <button
            className="texto centrado"
            onClick={() => {
              window.open("http://www.qualisfidelis.com", "_blank");
            }}
          >
            qualisfidelis.com
          </button>
          <button
            className="texto centrado"
            onClick={() => {
              window.open(
                "https://www.organismouruguayodeacreditacion.org/wp-content/uploads/2023/10/OUAIMP034-Qualis-Fidelis-Alcance-V-1.pdf",
                "_blank"
              );
            }}
          >
            Información de acreditacion
          </button>
        </div>
      </footer>
    </div>
  );
}
