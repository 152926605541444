import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { accessAPI } from "../../utils/utils.js";
import Formulario from "../../elementos/formulario/Formulario.js";
import Notificacion from "../../elementos/notificacion/Notificacion.js";
import logoVerticalBlanco from "../../images/logoVerticalBlanco.png";

export default function SolicitudCambioPassword() {
  const [mensajeNotificacion, setMensajeNotificacion] = useState();
  const [loginFormLoader, setLoginFormLoader] = useState(false);
  const [mostrarAviso, setMostrarAviso] = useState(false);

  const navigate = useNavigate();

  // Configuración del formulario de login
  const loginFormConfig = {
    secciones: [
      {
        campos: [
          {
            nombre: "email",
            tipoitemformularioid: 2,
            etiqueta: "Email",
            requerido: true,
          },
        ],
      },
    ],
    submit: { callback: solicitarCambio },
    botones: [
      {
        etiqueta: "Solicitar cambio",
        clases: "relleno loginSubmit",
        tipo: "submit",
      },
    ],
  };

  // Submit del email
  function solicitarCambio(contenidoCampos) {
    setLoginFormLoader(true);
    accessAPI(
      "POST",
      "recuperarPassword",
      JSON.stringify(contenidoCampos),
      (response) => {
        setLoginFormLoader(false);
        setMostrarAviso(true);
      },
      (response) => {
        setMensajeNotificacion({
          mensaje: response[0].msg,
          temporal: true,
          error: true,
        });
        setLoginFormLoader(false);
      }
    );
  }

  return (
    <div className="seccion login">
      <Notificacion config={mensajeNotificacion} />
      <main>
        <div className="flexContainer vertical">
          <div className="logoLoginContainer centrado">
            <img className="logoLogin" src={logoVerticalBlanco} alt="logo" />
          </div>
          {!mostrarAviso ? (
            <div className="recuadro centrado recuadroLogin">
              <div className="recuadroContent flexContainer vertical">
                <h1 className="margenArriba centrado">
                  Ingrese su email y recibirá un link para el reinicio de
                  contraseña
                </h1>
                <Formulario config={loginFormConfig} loader={loginFormLoader} />
              </div>
            </div>
          ) : (
            <div className="recuadro centrado recuadroLogin">
              <div className="recuadroContent flexContainer vertical">
                <p>
                  Por favor, revise su correo electrónico para continuar con el
                  cambio de contraseña.
                </p>
                <p>Una vez actualizada podrá volver a ingresar. </p>
              </div>
            </div>
          )}
          <div className="centrado">
            <button
              className="texto centrado blanco"
              onClick={() => {
                navigate("/");
              }}
            >
              Volver al login
            </button>
          </div>
        </div>
      </main>
      <div className="loginFooter">
        © <a href="http://qualisfidelis.com">qualisfidelis.com</a>
      </div>
    </div>
  );
}
