import loaderImagen from "../../images/loaderImagen.png";
import "./loader.css";

export default function Loader(props) {
  return (
    <div className="loaderContainer flexContainer vertical">
      <div className={`loaderImagenContainer centrado ${props.clases}`}>
        <img className="loaderImagen" src={loaderImagen} alt="loader" />
      </div>
      <div className="loaderTexto centrado">{props.children}</div>
    </div>
  );
}
