import React, { useState, useRef } from "react";

export default function FormularioCampo(props) {
  const campoRef = useRef();
  const [campo] = useState(props.campo);

  // Cuando cambia el campo, actualiza el padre
  function campoOnChange() {
    let contenido;
    if (campo.tipoitemformularioid === 6) {
      contenido = +campoRef.current.checked;
    } else {
      contenido = campoRef.current.value;
    }
    props.onUpdate(props.campo.nombre, contenido);
  }

  // Los tipos de campo son:
  // 1 - texto
  // 2 - email
  // 3 - password
  // 4 - numero
  // 5 - dropdown
  // 6 - checkbox

  return (
    <div className="prettyInputContainer flexContainer vertical">
      <div className="prettyInputEtiquetaContainer centrado">
        <div className="prettyInputEtiqueta centrado">
          {campo.etiqueta}
          {props.mensaje && props.estado === "error" && (
            <span className="mensajeError"> - {props.mensaje}</span>
          )}
        </div>
      </div>
      {(campo.tipoitemformularioid === 1 ||
        campo.tipoitemformularioid === 2) && (
        <input
          type="text"
          onChange={campoOnChange}
          className={props.estado + " prettyInput centrado"}
          disabled={props.disabled}
          defaultValue={
            props.campo.respuesta ? decodeURI(props.campo.respuesta) : null
          }
          ref={campoRef}
        />
      )}
      {campo.tipoitemformularioid === 3 && (
        <input
          type="password"
          onChange={campoOnChange}
          className={props.estado + " prettyInput centrado"}
          disabled={props.disabled}
          defaultValue={props.campo.respuesta}
          ref={campoRef}
        />
      )}
      {campo.tipoitemformularioid === 4 && (
        <input
          type="number"
          onChange={campoOnChange}
          className={props.estado + " prettyInput centrado"}
          disabled={props.disabled}
          defaultValue={props.campo.respuesta}
          ref={campoRef}
        />
      )}
      {campo.tipoitemformularioid === 5 && (
        <select
          defaultValue={campo.sinOpcionInicial ? "0" : campo.opciones[0].value}
          disabled={props.disabled}
          ref={campoRef}
          onChange={campoOnChange}
          className={props.estado + " prettyInput centrado"}
        >
          {campo.sinOpcionInicial && <option value="0" disabled />}
          {campo.opciones &&
            campo.opciones.map((opcion) => {
              return (
                <option value={opcion.value} key={opcion.value}>
                  {opcion.etiqueta}
                </option>
              );
            })}
        </select>
      )}
      {campo.tipoitemformularioid === 6 && (
        <>
          <input
            type="checkbox"
            onChange={campoOnChange}
            className={props.estado + " prettyCheckbox centrado"}
            disabled={props.disabled}
            defaultChecked={props.campo.respuesta}
            ref={campoRef}
            id="checkbox"
          />
        </>
      )}
    </div>
  );
}
