import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import Formulario from "../../elementos/formulario/Formulario";
import Loader from "../../elementos/loader/Loader";
import { accessAPI, downloadFile } from "../../utils/utils";
import logoHorizontal from "../../images/logoHorizontal.png";
import "./visualizacionEtiqueta.css";

export default function VisualizacionEtiqueta() {
  const [loader, setLoader] = useState(true);
  const [modelos, setModelos] = useState();
  const [loaderBusqueda, setLoaderBusqueda] = useState(false);
  const [etiquetaDescargada, setEtiquetaDescargada] = useState(false);

  const { marcaVehiculo } = useParams();

  // Configuración del formulario de modelo
  const modeloFormConfig = {
    secciones: [
      {
        campos: [
          {
            nombre: "nombre",
            tipoitemformularioid: 1,
            etiqueta: "Nombre completo",
            requerido: true,
          },
          {
            nombre: "ci",
            tipoitemformularioid: 4,
            etiqueta: "Cédula de identidad (sin puntos ni guiones)",
            requerido: true,
          },
          {
            nombre: "productoid",
            tipoitemformularioid: 5,
            etiqueta: "Seleccione el modelo",
            requerido: true,
            sinOpcionInicial: true,
            opciones: modelos,
          },
        ],
      },
    ],
    submit: { callback: modeloFormSubmit },
    botones: [
      {
        etiqueta: "Ver etiqueta",
        clases: "relleno loginSubmit",
        tipo: "submit",
      },
    ],
  };

  // Hace la solicitud de descarga de etiqueta
  function modeloFormSubmit(respuestaFormulario) {
    // Necesito enviar un GET ya que el método de descarga de acrhivos hace un GET
    // para enviar los datos a la API necesito armar queryparams
    setLoaderBusqueda(true);
    let queryparams = "";
    for (const parametro in respuestaFormulario) {
      queryparams += `${parametro}=${respuestaFormulario[parametro]}&`;
    }

    downloadFile(
      `visualizacionetiqueta/registro/${respuestaFormulario.productoid}?${queryparams}`,
      "Etiqueta producto.pdf",
      (respuesta) => {
        setLoaderBusqueda(false);
        setEtiquetaDescargada(true);
      },
      (respuesta) => {
        setLoaderBusqueda(false);
        alert(respuesta[0].msg);
      }
    );
  }

  // Cuando carga el componente, va a buscar los modelos de la marca
  useEffect(() => {
    accessAPI(
      "GET",
      `visualizacionetiqueta/${marcaVehiculo}`,
      null,
      (respuesta) => {
        setModelos(respuesta);
      },
      (respuesta) => {
        console.log(respuesta);
      }
    );
  }, [marcaVehiculo]);

  // Cuando carga los mdoelos, apaga el loader
  useEffect(() => {
    if (modelos) {
      setLoader(false);
    }
  }, [modelos]);

  return (
    <div>
      <div className="seccion registro">
        <header className="flexContainer izquierda">
          <img className="logo centrado" src={logoHorizontal} alt="logo" />
        </header>
        <main className="mensajeCliente">
          <h2>Visualizador de etiquetas</h2>
          <p>
            Ingrese sus datos y seleccione el modelo del vehículo para
            visualizar la etiqueta.
          </p>
          <div className="tarjeta centrada gris buscador">
            {loader && <Loader>Buscando modelos</Loader>}
            {etiquetaDescargada && (
              <div>
                <p>Etiqueta descargada correctamente.</p>
                <p>
                  Si no fue desplegada automáticamente, puede encontrarla en la
                  sección de descargas de su dispositivo.
                </p>
              </div>
            )}
            {!etiquetaDescargada && !loader && modelos.length === 0 && (
              <div>No se encontraron modelos de la marca especificada</div>
            )}
            {!etiquetaDescargada && !loader && modelos.length !== 0 && (
              <Formulario config={modeloFormConfig} loader={loaderBusqueda} />
            )}
          </div>
        </main>
        <footer>
          <div className="flexContainer izquierda inline botonera">
            <button
              className="texto centrado"
              onClick={() => {
                window.open("http://www.qualisfidelis.com", "_blank");
              }}
            >
              qualisfidelis.com
            </button>
            <button
              className="texto centrado"
              onClick={() => {
                window.open(
                  "https://www.organismouruguayodeacreditacion.org/wp-content/uploads/2023/10/OUAIMP034-Qualis-Fidelis-Alcance-V-1.pdf",
                  "_blank"
                );
              }}
            >
              Información de acreditacion
            </button>
          </div>
        </footer>
      </div>
    </div>
  );
}
