import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Formulario from "../../elementos/formulario/Formulario";
import Notificacion from "../../elementos/notificacion/Notificacion";
import "./registroEmpresa.css";
import { accessAPI } from "../../utils/utils.js";
import logoHorizontal from "../../images/logoHorizontal.png";

export default function RegistroEmpresa() {
  const [mensajeNotificacion, setMensajeNotificacion] = useState();
  const [formLoader, setFormLoader] = useState(false);
  const [confirmacionRegistro, setConfirmacionRegistro] = useState(false);

  const navigate = useNavigate();

  const registroEmpresaFormConfig = {
    secciones: [
      {
        nombre: "Datos de la empresa",
        campos: [
          {
            nombre: "razonsocial",
            tipoitemformularioid: 1,
            etiqueta: "Razón social",
            requerido: true,
          },
          {
            nombre: "rut",
            tipoitemformularioid: 1,
            etiqueta: "RUT",
          },
          {
            nombre: "direccion",
            tipoitemformularioid: 1,
            etiqueta: "Direccion",
            requerido: true,
          },
          {
            nombre: "codigopostal",
            tipoitemformularioid: 4,
            etiqueta: "Código postal",
            requerido: true,
          },
          {
            nombre: "ciudad",
            tipoitemformularioid: 1,
            etiqueta: "Ciudad",
            requerido: true,
            respuesta: "Montevideo",
          },
          {
            nombre: "estado",
            tipoitemformularioid: 1,
            etiqueta: "Estado",
            requerido: true,
            respuesta: "Montevideo",
          },
          {
            nombre: "pais",
            tipoitemformularioid: 1,
            etiqueta: "País",
            requerido: true,
            respuesta: "Uruguay",
          },
          {
            nombre: "direcciondeposito",
            tipoitemformularioid: 1,
            etiqueta: "Direccion del depósito",
            requerido: true,
          },
          {
            nombre: "codigopostaldeposito",
            tipoitemformularioid: 4,
            etiqueta: "Código postal del depósito",
            requerido: true,
          },
          {
            nombre: "ciudaddeposito",
            tipoitemformularioid: 1,
            etiqueta: "Ciudad del depósito",
            requerido: true,
            respuesta: "Montevideo",
          },
          {
            nombre: "estadodeposito",
            tipoitemformularioid: 1,
            etiqueta: "Estado del depósito",
            requerido: true,
            respuesta: "Montevideo",
          },
          {
            nombre: "paisdeposito",
            tipoitemformularioid: 1,
            etiqueta: "País del depósito",
            requerido: true,
            respuesta: "Uruguay",
          },
          {
            nombre: "telefono",
            tipoitemformularioid: 1,
            etiqueta: "Teléfono",
          },
        ],
      },
      {
        nombre: "Responsable de certificación",
        descripcion:
          "Usuario que gestionará los certificados desde esta plataforma. Puede actualizarse en caso de ser necesario.",
        campos: [
          {
            nombre: "nombreresponsable",
            tipoitemformularioid: 1,
            etiqueta: "Nombre",
            requerido: true,
          },
          {
            nombre: "apellidoresponsable",
            tipoitemformularioid: 1,
            etiqueta: "Apellido",
            requerido: true,
          },
          {
            nombre: "emailresponsable",
            tipoitemformularioid: 2,
            etiqueta: "Email",
            requerido: true,
          },
          {
            nombre: "telefonoresponsable",
            tipoitemformularioid: 1,
            etiqueta: "Celular",
            requerido: true,
          },
          {
            nombre: "cargoresponsable",
            tipoitemformularioid: 1,
            etiqueta: "Cargo",
          },
        ],
      },
    ],
    submit: { callback: crearEmpresa },
    botones: [
      { etiqueta: "Enviar", clases: "azul", tipo: "submit" },
      {
        callback: () => {
          navigate("/");
        },
        etiqueta: "Cancelar",
        clases: "rojo",
        tipo: "button",
      },
    ],
  };

  function crearEmpresa(camposFormulario) {
    setFormLoader(true);
    accessAPI(
      "POST",
      "cliente",
      JSON.stringify(camposFormulario),
      (response) => {
        setConfirmacionRegistro(true);
        setFormLoader(false);
      },
      (response) => {
        setMensajeNotificacion({
          mensaje: response[0].msg,
          temporal: true,
          error: true,
        });
        setFormLoader(false);
      }
    );
  }

  return (
    <div className="seccion registro">
      <Notificacion config={mensajeNotificacion} />
      <header className="flexContainer izquierda">
        <img className="logo centrado" src={logoHorizontal} alt="logo" />
      </header>
      {!confirmacionRegistro && (
        <main className="flexContainer vertical registroEmpresaFormularioContainer">
          <div className="formularioContainer centrado">
            <Formulario
              className="centrado"
              config={registroEmpresaFormConfig}
              loader={formLoader}
            />
          </div>
        </main>
      )}

      {confirmacionRegistro && (
        <main className="flexContainer vertical registroEmpresaFormularioContainer">
          <p className="centrado">
            Su empresa ha sido registrada exitosamente.
          </p>
          <p className="centrado">
            Les crearemos una cuenta y recibirá un correo con instrucciones para
            el ingreso.
          </p>
          <p className="centrado">
            Muchas gracias por confiar en Qualis Fidelis.
          </p>
          <button
            className="texto centrado"
            onClick={() => {
              navigate("/");
            }}
          >
            Volver al login
          </button>
        </main>
      )}

      <footer>
        <div className="flexContainer izquierda inline botonera">
          <button
            className="texto centrado"
            onClick={() => {
              window.open("http://www.qualisfidelis.com", "_blank");
            }}
          >
            qualisfidelis.com
          </button>
          <button
            className="texto centrado"
            onClick={() => {
              window.open(
                "http://www.organismouruguayodeacreditacion.org",
                "_blank"
              );
            }}
          >
            Información de acreditacion
          </button>
        </div>
      </footer>
    </div>
  );
}
