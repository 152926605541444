import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { accessAPI } from "../../utils/utils.js";
import Formulario from "../../elementos/formulario/Formulario.js";
import Notificacion from "../../elementos/notificacion/Notificacion.js";
import logoVerticalBlanco from "../../images/logoVerticalBlanco.png";

export default function CambioPassword() {
  const [mensajeNotificacion, setMensajeNotificacion] = useState();
  const [loginFormLoader, setLoginFormLoader] = useState(false);

  const navigate = useNavigate();
  const { token } = useParams("token");

  // Configuración del formulario de cambio de contraseña
  const cambioContraConfig = {
    secciones: [
      {
        campos: [
          {
            nombre: "password",
            tipoitemformularioid: 3,
            etiqueta: "Nueva contraseña",
            requerido: true,
          },
          {
            nombre: "passwordrepeat",
            tipoitemformularioid: 3,
            etiqueta: "Confirmar contraseña",
            requerido: true,
          },
        ],
      },
    ],
    submit: { callback: cambiarContra },
    botones: [
      {
        etiqueta: "Confirmar",
        clases: "relleno loginSubmit",
        tipo: "submit",
      },
    ],
  };

  //Cambio de contraseña y redirección a login
  function cambiarContra(contenidoCampos) {
    if (contenidoCampos) setLoginFormLoader(true);
    accessAPI(
      "PUT",
      `recuperarPassword/${token}`,
      JSON.stringify(contenidoCampos),
      (response) => {
        setMensajeNotificacion({
          mensaje: response[0].msg,
          temporal: true,
          error: true,
        });
        setLoginFormLoader(false);
        alert("Contraseña actualizada correctamente");
        navigate("/");
      },
      (response) => {
        setLoginFormLoader(false);
        setMensajeNotificacion({
          mensaje: response[0].msg,
          temporal: true,
          error: true,
        });
      }
    );
  }

  return (
    <div className="seccion login">
      <Notificacion config={mensajeNotificacion} />
      <main>
        <div className="flexContainer vertical">
          <div className="logoLoginContainer centrado">
            <img className="logoLogin" src={logoVerticalBlanco} alt="logo" />
          </div>
          <div className="recuadro centrado recuadroLogin">
            <div className="recuadroContent flexContainer vertical">
              <h1 className="margenArriba centrado">
                Cree una contraseña nueva
              </h1>
              <p>
                Una vez configurada podrá utilizarla para ingresar al sistema.
              </p>
              <Formulario
                config={cambioContraConfig}
                loader={loginFormLoader}
              />
            </div>
          </div>
          <div className="centrado">
            <button
              className="texto centrado blanco"
              onClick={() => {
                navigate("/");
              }}
            >
              Cancelar y volver al login
            </button>
          </div>
        </div>
      </main>
      <div className="loginFooter">
        © <a href="http://qualisfidelis.com">qualisfidelis.com</a>
      </div>
    </div>
  );
}
