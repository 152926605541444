import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { accessAPI } from "../../utils/utils.js";
import "../login/login.css";
import Formulario from "../../elementos/formulario/Formulario";
import Notificacion from "../../elementos/notificacion/Notificacion";
import logoVerticalBlanco from "../../images/logoVerticalBlanco.png";

export default function Login() {
  const [mensajeNotificacion, setMensajeNotificacion] = useState();
  const [loginFormLoader, setLoginFormLoader] = useState(false);

  const navigate = useNavigate();

  // Configuración del formulario de login
  const loginFormConfig = {
    secciones: [
      {
        campos: [
          {
            nombre: "username",
            tipoitemformularioid: 2,
            etiqueta: "Email",
            requerido: true,
          },
          {
            nombre: "password",
            tipoitemformularioid: 3,
            etiqueta: "Contraseña",
            requerido: true,
          },
        ],
      },
    ],
    submit: { callback: loginFormSubmit },
    botones: [
      {
        etiqueta: "Ingresar",
        clases: "relleno loginSubmit",
        tipo: "submit",
      },
    ],
  };

  // Submit de formulario de login
  function loginFormSubmit(contenidoCampos) {
    setLoginFormLoader(true);
    accessAPI(
      "POST",
      "oauth",
      JSON.stringify(contenidoCampos),
      // Si la autenticación es correcta, guarda
      // el token en LS
      (response) => {
        window.location.href = response.url;
      },
      (response) => {
        setMensajeNotificacion({
          mensaje: response[0].msg,
          temporal: true,
          error: true,
        });
        setLoginFormLoader(false);
      }
    );
  }

  return (
    <div className="seccion login">
      <Notificacion config={mensajeNotificacion} />
      <main>
        <div className="flexContainer vertical">
          <div className="logoLoginContainer centrado">
            <img className="logoLogin" src={logoVerticalBlanco} alt="logo" />
          </div>
          <div className="recuadro centrado recuadroLogin">
            <div className="recuadroContent flexContainer vertical">
              <h1 className="margenArriba centrado">
                Gestiona tus certificados
              </h1>
              <Formulario config={loginFormConfig} loader={loginFormLoader} />
            </div>
          </div>
          <div className="centrado">
            <button
              className="texto centrado blanco"
              onClick={() => {
                navigate("/registro");
              }}
            >
              Registrar empresa
            </button>
            <button
              className="texto centrado blanco"
              onClick={() => {
                navigate("/solicitudCambioPassword");
              }}
            >
              Olvidé mi contraseña
            </button>
          </div>
        </div>
      </main>
      <div className="loginFooter">
        © <a href="http://qualisfidelis.com">qualisfidelis.com</a>
      </div>
    </div>
  );
}
