import { useState, useEffect } from "react";
import Loader from "../../elementos/loader/Loader";
import { accessAPI } from "../../utils/utils.js";

export default function ProductoEnCertificado(props) {
  const [loader, setLoader] = useState(true);
  const [mostrandoDetalles, setMostrandoDetalles] = useState(false);
  const [masDetalles, setMasDetalles] = useState();

  function mostrarDetalles() {
    setMostrandoDetalles(true);
    if (!masDetalles) {
      accessAPI(
        "GET",
        `certificado/detalles/${props.certificadoTipo}/${props.certificadoNumero}/${props.producto.id}`,
        null,
        (respuesta) => {
          setMasDetalles(respuesta);
        },
        (respuesta) => {
          alert(respuesta[0].msg);
        }
      );
    }
  }

  // Cuando recibe el resto de los detalles del producto, apaga el loader
  useEffect(() => {
    if (masDetalles) {
      setLoader(false);
    }
  }, [masDetalles]);

  return (
    <div className="unProducto">
      {props.producto.producto_x_productocampospropios.map(
        (campoPropio, indexCampoPropio) => {
          return (
            <div className="campoPropio" key={indexCampoPropio}>
              <span className="campoPropio">
                {campoPropio.productocampospropios.etiqueta} -{" "}
                {campoPropio.valor}
              </span>
            </div>
          );
        }
      )}
      {!mostrandoDetalles && (
        <button className="texto" onClick={mostrarDetalles}>
          Ver detalles
        </button>
      )}
      {mostrandoDetalles && (
        <div>
          {loader && <Loader clases="chico conMargen" />}
          {!loader && (
            <div>
              {masDetalles.producto[0].producto_x_productocampospropios.map(
                (campoPropio, indexCampoPropio) => {
                  return (
                    <div className="campoPropio" key={indexCampoPropio}>
                      <span className="campoPropio">
                        {campoPropio.productocampospropios.etiqueta} -{" "}
                        {campoPropio.valor}
                      </span>
                    </div>
                  );
                }
              )}

              <button
                className="texto"
                onClick={() => {
                  console.log("hola");
                  setMostrandoDetalles(false);
                }}
              >
                Ocultar detalles
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
