const texts = {
  USER_PLACEHOLDER: "Usuario",
  NAME_PLACEHOLDER: "Nombre completo",
  EMAIL_PLACEHOLDER: "Email",
  PASSWORD_PLACEHOLDER: "Contraseña",
  ENTER: "Ingresar",
  ACCEPT: "Aceptar",
  CANCEL: "Cancelar",
  DELETE: "Eliminar",
  CREATE_ACCOUNT: "Crear cuenta",
  FORGOT_PASSWORD: "Olvidé mi contraseña",
  API_ERROR:
    "Ocurrió un error inesperado, por favor inéntalo denuevo más tarde.",
  ACTIVE_CARDS: "Cartas activas",
  SOLD_CARDS: "Cartas vendidas",
  DEACTIVATE: "Desactivar",
  ACTIVATE: "Activar",
  SOLD_ON: "Vendida",
  ADD_CARD: "Añadir carta",
  CARD_NAME: "Escribe el nombre de la carta",
  SEARCH: "Buscar",
  SELECT_VERSION: "Selecciona la versión de tu carta.",
  CONDITION: "Estado",
  LANGUAGE: "Idioma",
  QUANTITY: "Cantidad",
  ADDING: "Añadiendo",
  ADD: "Añadir",
  HOME: "Home",
  MY_COLLECTION: "Colección",
  MY_SALES: "Ventas",
  MY_ACCOUNT: "Cuenta",
  BUY: "Comprar",
  LOGIN: "Ingresar",
  LOGOUT: "Salir",
  CREATE_USERNAME: "Nombre de usuario",
  CREATE_NAME: "Nombre completo",
  CREATE_EMAIL: "Email",
  CREATE_PASSWORD: "Contraseña",
  CONFIRM_DELETE_CARD_START: "¿Eliminar ",
  CONFIRM_DELETE_CARD_END: "?",
  AVAILABLE: "Disponibles: ",
  I_WANT_IT: "Lo quiero",
  STORE_SEARCH: "Busca en la tienda",
  SELL_CARDS: "Vender",
  PAYMENT: "Pagar",
  SELECT: "Seleccionar",
  CARDS_IN_SALE: "Cartas en la venta",
  FINISH_SALE: "Finalizar venta",
  CARDS_AVAILABLE_IN_STORE: "Cartas disponibles en la tienda",
  UPDATE_DETAILS: "Actualiza tus datos",
  UPDATED_DETAILS: "Tus datos han sido actualizados.",
  UPDATE_PASSWORD: "Actualiza tu contraseña",
  CURRENT_PASSWORD: "Contraseña actual",
  NEW_PASSWORD: "Nueva contraseña",
  PAYMENT_TITLE: "Registrar un pago",
  AMMOUNT_PLACEHOLDER: "Monto",
  SELECT_USER: "Usuario",
  PAYMENT_PROCESSED: "Pago registrado exitosamente",
  SELECT_SET: "Seleccione un set para ver sus cartas",
  CLEAR: "Limpiar",
  FILTERS: " Filtros",
  LOADING_PRICE: "Cargando precio",
  PRICE_NOT_FOUND: "Precio no encontrado",
  SORT_BINDER: "Organizar carpeta",
};

export default texts;
