import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "./secciones/login/Login";
import RegistroEmpresa from "./secciones/registroempresa/RegistroEmpresa";
import CertNoEncontrado from "./secciones/certnoencontrado/CertNoEncontrado";
import DescargarCertificado from "./secciones/descargarcertificado/DescargarCertificado";
import BusquedaCertificado from "./secciones/busquedaCertificado/BusquedaCertificado";
import NotFound from "./secciones/notFound/NotFound";
import SolicitudCambioPassword from "./secciones/recuperarcontrasenia/solicitudCambioPassword";
import CambioPassword from "./secciones/recuperarcontrasenia/cambioPassword";
import VisualizacionEtiqueta from "./secciones/visualizacionEtiqueta/VisualizacionEtiqueta";

class Router extends React.Component {
  render() {
    return (
      <Routes>
        <Route path="/dc/:certificadoHash" element={<DescargarCertificado />} />
        <Route path="/certnoencontrado" element={<CertNoEncontrado />} />
        <Route path="/registro" element={<RegistroEmpresa />} />
        <Route path="/cert" element={<BusquedaCertificado />} />
        <Route path="/c" element={<BusquedaCertificado />} />
        <Route
          path="/c/:certificadoTipo/:certificadoNumero"
          element={<BusquedaCertificado />}
        />
        <Route path="/:marcaVehiculo" element={<VisualizacionEtiqueta />} />
        <Route path="/" element={<Login />} />
        <Route path="*" element={<NotFound />} />
        <Route
          path="/solicitudCambioPassword"
          element={<SolicitudCambioPassword />}
        />
        <Route path="/cc/:token" element={<CambioPassword />} />
      </Routes>
    );
  }
}

export default Router;
